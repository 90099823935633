<template>
  <tab-layout :items="tabs" />
</template>

<script>
import TabLayout from '@cpanel/layouts/tabs'

export default {
  components: { TabLayout },
  data() {
    return {
      tabs: [
        { title: 'Сайты и виджеты', link: 'webapp' },
        { title: 'Оплата', link: 'billing' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
