<template>
  <div>
    <v-tabs
      v-model="tabs"
      dark
      background-color="primary"
      slider-color="orange"
    >
      <v-tab
        v-for="(tabsItem, idx) in tabsItems"
        :key="idx"
        :to="tabsItem.link"
      >
        <v-badge :value="!!tabsItem.badge" :content="tabsItem.badge" right color="red" :overlap="false">
          <span class="pl-2 pr-2">{{ tabsItem.title }}</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-slide-y-transition mode="out-in">
      <router-view />
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      tabs: null,
      tabsItems: { ...this.items }
    }
  },
  watch: {
    items(newValue) {
      this.tabsItems = newValue.slice()
    }
  }
}
</script>

<style scoped>

</style>
